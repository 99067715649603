<template>
  <div class="column items-center justify-start bg-grey-3">
    <q-card flat bordered style="width:1024px; " class="q-mt-xl">
      <q-card-section class="row justify-between">
        <q-btn
          dense
          outline
          color="primary"
          label="Download PDF"
          @click="download()"
        />
        <div class="row q-gutter-sm">
          <q-select
            outlined
            dense
            v-if="user.is_super_user == '1'"
            v-model="selMusyrif"
            label="Musyrif"
            :options="musyrif"
            @input="
              selSiswa = null;
              prepareData();
            "
          />
          <q-select
            dense
            outlined
            v-model="selSiswa"
            label="Siswa"
            :options="siswa"
            style="width:200px"
          />
        </div>
      </q-card-section>
    </q-card>

    <div v-if="selSiswa" ref="page_1" class="paper bg-white q-pa-lg q-my-xl">
      <p class="text-center ">
        <strong class="text-h6">LAPORAN PERKEMBANGAN AHLAK SISWA</strong>
        <br />
        <strong class="text-overline"
          >PENGASUHAN SMP PUTRA PERGURUAN ISLAM AR RISALAH</strong
        >
      </p>

      <q-card-section horizontal style="font-size:12px">
        <q-card-section class="text-bold col-8" v-if="selSiswa != null">
          <p class="q-ma-none row" style="height:30px">
            <a class="col-2">Nama Siswa</a>
            <a class="col-10">: {{ selSiswa.label }}</a>
          </p>
          <p class="q-ma-none row" style="height:30px">
            <a class="col-2">Nama Kamar</a>
            <a class="col-10">: {{ selSiswa.nama_kamar }}</a>
          </p>
          <p class="q-ma-none row" style="height:30px">
            <a class="col-2">Asrama</a>
            <a class="col-10">: {{ selSiswa.nama_asrama }}</a>
          </p>
        </q-card-section>

        <q-card-section class="text-bold col-4" v-if="selSiswa != null">
          <p class="q-ma-none row" style="height:30px">
            <a class="col-6">Semester</a>
            <a class="col-6">: {{ selSiswa.semester }}</a>
          </p>
          <p class="q-ma-none row" style="height:30px">
            <a class="col-6">Tahun Ajaran</a>
            <a class="col-6">: {{ selSiswa.tahun_ajar }}</a>
          </p>
        </q-card-section>
      </q-card-section>
      <q-markup-table wrap-cells dense flat bordered separator="cell">
        <thead>
          <tr>
            <th style="width:5%">
              <strong>No</strong>
            </th>
            <th style="width:50%">
              <strong>Penilaian</strong>
            </th>
            <th style="width:10%">
              <strong>Nilai</strong>
            </th>
            <th style="width:35%">
              <strong>Keterangan</strong>
            </th>
          </tr>
        </thead>
        <tbody v-if="selSiswa != null"></tbody>
        <tbody
          v-for="(el, j) in selSiswa.data_nilai"
          :key="j"
          style="display: contents;"
        >
          <tr class="bg-grey-5">
            <td colspan="3" class>
              <strong>ASPEK {{ el.aspek }}</strong>
            </td>
            <td class="text-center bg-white" :rowspan="el.nilai.length + 1">
              {{ el.catatan }}
              <!-- <div
                  class="bg-white column justify-start"
                  style="height:100%; overflow: visible;"
                >{{ el.catatan }}</div>-->
            </td>
          </tr>
          <tr v-for="(val, i) in el.nilai" :key="i">
            <td class="text-center">{{ val.number }}</td>
            <td>{{ val.nama }}</td>
            <td class="text-center">
              <strong>{{ val.predikat }}</strong>
            </td>
          </tr>
        </tbody>

        <tr class="text-center">
          <td class="bg-grey-5" colspan="2">
            <strong>Rata-Rata</strong>
          </td>
          <td colspan="2">
            <strong>{{ selSiswa.predikat_rerata }}</strong>
          </td>
        </tr>
      </q-markup-table>

      <div class="q-mt-lg" style="font-size:12px">
        <p>
          <strong>Catatan Orang Tua / Wali</strong>
        </p>
        <div>
          <q-card square flat bordered style=" height: 5em"></q-card>
        </div>
      </div>
      <q-card-section
        horizontal
        class="q-mt-lg row items-end"
        style="font-size:12px"
      >
        <q-card-section style="width:50%">
          <a>
            <strong>Keterangan Nilai Kualitatif</strong>
          </a>
          <br />
          <q-markup-table dense flat bordered>
            <tbody class="text-caption text-grey">
              <tr>
                <td>M</td>
                <td>Membudaya</td>
                <td>90 - 100</td>
              </tr>
              <tr>
                <td>B</td>
                <td>Berkembang</td>
                <td>80 - 89</td>
              </tr>
              <tr>
                <td>MBK</td>
                <td>Mulai Berkembang</td>
                <td>70 - 79</td>
              </tr>
              <tr>
                <td>MB</td>
                <td>Membutuhkan Bimbingan</td>
                <td>0 - 69</td>
              </tr>
            </tbody>
          </q-markup-table>
        </q-card-section>
        <q-card-section class="text-center" style="width:30%">
          <a>Mengetahui,</a>
          <br />
          <a>Orang Tua / Wali</a>
          <br />
          <br />
          <br />
          <br />..............................
        </q-card-section>
        <q-card-section class="text-center" style="width:20%">
          <a>Padang, 19 Desember 2020</a>
          <br />
          <a>Musyrif</a>
          <br />
          <br />
          <br />
          <br />Meica Indra, S.Pd
        </q-card-section>
      </q-card-section>
    </div>
  </div>
</template>

<script>
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import moment from "moment";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      user: {},
      musyrif: [],
      selMusyrif: {
        value: null,
      },

      catatan: "",
      selSiswa: null,
      siswa: [],
      id_musyrif: 1,
    };
  },
  async mounted() {
    await this.getUser();
    if (this.user.is_super_user == "1") {
      await this.getMusyrif();
    } else {
      this.selMusyrif.value = this.user.id;
    }
    await this.prepareData();
  },
  methods: {
    ...mapGetters(["getCurrentUser"]),
    async getMusyrif() {
      let resp = await this.$http.get(`/penilaian/absensi/getmusyrif`);
      this.musyrif = resp.data;
      if (resp.data.length > 0) {
        this.selMusyrif = resp.data[0];
      }
    },
    async getUser() {
      this.user = await this.getCurrentUser();
    },
    async prepareData() {
      await this.getSiswa();
      await this.getNilai();
      this.selSiswa = this.siswa[0];
    },
    async getNilai() {
      let temp = JSON.parse(JSON.stringify(this.siswa));
      let resp = await this.$http.post(
        `/hasil/raport/getnilai/${localStorage.getItem(
          "jenjang"
        )}/${localStorage.getItem("id_tahun_ajaran")}`,
        this.siswa
      );
      for (let i in resp.data) {
        let number = 0;
        let total_rerata = 0;
        for (let aspek of resp.data[i]) {
          for (let nilai of aspek.nilai) {
            let a = parseFloat(nilai.batas_a);
            let b = parseFloat(nilai.batas_b);
            let c = parseFloat(nilai.batas_c);
            let d = parseFloat(nilai.batas_d);
            if(!nilai.nilai_musyrif){
              nilai.nilai_musyrif = 0
            }
            if(!nilai.nilai_pekan){
              nilai.nilai_pekan = 0
            }
            nilai.nilai_akhir = parseFloat(
              (parseFloat(nilai.nilai_musyrif) +
                parseFloat(nilai.nilai_pekan)) /
                2
            );
            if (nilai.nilai_akhir >= a) {
              nilai.predikat = "M";
            } else if (nilai.nilai_akhir < a && nilai.nilai_akhir >= b) {
              nilai.predikat = "B";
            } else if (nilai.nilai_akhir < b && nilai.nilai_akhir >= c) {
              nilai.predikat = "MBK";
            } else if (nilai.nilai_akhir < c && nilai.nilai_akhir >= d) {
              nilai.predikat = "MB";
            }
            number++;
            nilai.number = number;
            total_rerata = total_rerata + nilai.nilai_akhir;
          }
        }
        if(total_rerata == 0 || number == 0){
          temp[i].rerata = 0
        }else{
          temp[i].rerata = parseFloat(total_rerata / number); 
          }
        console.log(total_rerata);
        if (temp[i].rerata >= 90) {
          temp[i].predikat_rerata = "M";
        } else if (temp[i].rerata >= 80 && temp[i].rerata <= 89) {
          temp[i].predikat_rerata = "B";
        } else if (temp[i].rerata >= 70 && temp[i].rerata <= 79) {
          temp[i].predikat_rerata = "MBK";
        } else if (temp[i].rerata <= 69) {
          temp[i].predikat_rerata = "MB";
        }
        temp[i].data_nilai = resp.data[i];
      }
      this.siswa = temp;
    },
    async getSiswa() {
      let resp = await this.$http.get(
        `/hasil/raport/getdatasiswa/${this.selMusyrif.value}`
      );
      for (let item of resp.data) {
        if (parseInt(moment().format("MM")) > 6) {
          item.semester = `1 (Satu) / Ganjil`;
          item.tahun_ajar = `${moment().format("YYYY")}/${parseInt(
            moment().format("YYYY")
          ) + 1}`;
        } else {
          item.semester = `2 (Dua) / Genap`;
          item.tahun_ajar = `${parseInt(moment().format("YYYY")) -
            1}/${moment().format("YYYY")}`;
        }
      }
      this.siswa = resp.data;
    },
    async download() {
      const doc = new jsPDF("p", "mm", "a4");
      html2canvas(this.$refs.page_1, { scale: 2 }).then((result) => {
        const gambar = result.toDataURL("image/png");
        doc.addImage(gambar, "JPEG", 0, 0, 210, 297, "alias1", "MEDIUM");
        doc.save("a4.pdf");
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.text-predikat {
  font-size: 36px;
}
.text-lebih-kecil {
  font-size: 10px;
}
.text-kecil {
  font-size: 12px;
}
.text-besar {
  font-size: 14px;
}
.paper {
  height: 297mm;
  width: 210mm;
}
</style>
